<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar">
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">My Trade Journals</h1>
                <h2 class="subtitle">
                  Log your trades and see your account balance growth over time
                </h2>
              </div>
              <div class="field is-grouped">
                <p class="control">
                  <button
                    @click="createTradeJournal()"
                    class="button is-success"
                  >
                    Create Journal
                  </button>
                </p>
              </div>
            </div>

            <div class="block">
              <div class="columns is-multiline">
                <div
                  v-for="tradeJournal in orderedTradeJournals"
                  :key="tradeJournal.id"
                  class="column is-one-fifth is-flex"
                >
                  <div
                    @click="openTradeJournal(tradeJournal)"
                    class="card is-clickable"
                  >
                    <div class="card-content">
                      <div class="block">
                        <p class="title is-5">{{ tradeJournal.title }}</p>
                        <p class="subtitle is-6">
                          {{ tradeJournal.description }}
                        </p>
                        <p class="title is-6">
                          {{ tradeJournal.timestamp | date }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showCreateJournalModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Trade Journal</p>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <label class="label">Title</label>
            <div class="field">
              <div class="control" style="flex: 1">
                <input
                  v-model.number="newJournal.title"
                  class="input"
                  type="text"
                />
              </div>
            </div>

            <div class="field">
              <label class="label">Description</label>
              <div class="control">
                <textarea
                  v-model.trim="newJournal.description"
                  class="textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            :class="{ 'is-loading': newJournalSaving }"
            @click="saveJournal()"
          >
            Save
          </button>
          <button class="button is-danger" @click="cancelCreate()">
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";

export default {
  name: "AccountMyTradingJournal",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      tradeJournals: [],
      showCreateJournalModal: false,
      newJournal: {
        title: null,
        description: null,
      },
      newJournalSaving: false,
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    const tradeJournals = await this.$bind(
      "user",
      db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
    );
    console.log(tradeJournals);
    this.tradeJournals = tradeJournals;
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  computed: {
    orderedTradeJournals() {
      return this.tradeJournals
        .map((x) => ({ ...x, id: x.id }))
        .sort((a, b) => a.timestamp - b.timestamp);
    },
  },
  methods: {
    createTradeJournal() {
      this.showCreateJournalModal = true;
    },
    async saveJournal() {
      this.newJournalSaving = true;
      const tradeJournal = await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .add({
          title: this.newJournal.title,
          description: this.newJournal.description,
          timestamp: new Date(),
          user: this.$store.state.userProfile.uid,
        });
      this.newJournalSaving = false;
      this.showCreateJournalModal = false;
      await this.openTradeJournal(tradeJournal);
    },
    cancelCreate() {
      this.newJournalSaving = false;
      this.showCreateJournalModal = false;
      this.newJournal.title = null;
      this.newJournal.description = null;
    },
    openTradeJournal(tradeJournal) {
      this.$router.push({
        name: "AccountMyTradeJournalsJournal",
        params: { journal: tradeJournal.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  .card-content {
    width: 100%;
    .title {
      flex: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}
</style>
